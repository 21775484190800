"use strict";
exports.__esModule = true;
var CODOM = (function () {
    function CODOM() {
    }
    CODOM.ready = function (callback) {
        if (document.readyState != 'complete') {
            document.addEventListener('DOMContentLoaded', function () {
                callback();
            });
        }
        else {
            callback();
        }
    };
    CODOM.createElementFromHTML = function (htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        return div.firstChild;
    };
    return CODOM;
}());
exports.CODOM = CODOM;
