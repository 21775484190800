"use strict";
exports.__esModule = true;
var codom_1 = require("../../../../../../controller/static/ts/codom");
var Webapp = (function () {
    function Webapp() {
        codom_1.CODOM.ready(function () {
            Webapp.showPopup();
        });
    }
    Webapp.showPopup = function () {
        if (document.querySelector('.popup') == null) {
            return;
        }
        if (this.popupAlreadyUsed() !== null) {
            return;
        }
        setTimeout(function () {
            sessionStorage.setItem('popup', 'opened');
            document.querySelector('.popup').classList.add('show');
        }, 3000);
    };
    Webapp.popupAlreadyUsed = function () {
        return sessionStorage.getItem('popup');
    };
    Webapp.closePopup = function () {
        if (document.querySelector('.popup') != null) {
            document.querySelector('.popup').classList.remove('show');
        }
    };
    return Webapp;
}());
exports.Webapp = Webapp;
